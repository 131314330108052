// Analytics
//
// This lib is designed to be a generic analytics wrapper
// We should not get locked into a specific analytics platform
// So we can add new platforms as needed
//
// Amplitude docs: https://www.docs.developers.amplitude.com/data/sdks/typescript-browser
import React from 'react'
import * as amplitude from '@amplitude/analytics-browser'
import * as Sentry from '@sentry/browser'

export type AnalyticsOpts = {
  amplitudeApiKey?: string
  disableAnalytics?: boolean
  environment: string
}

export enum AnalyticsEvent {
  PageViewed = 'page.viewed',
  LogoClicked = 'logo.clicked',
  HeroButtonClicked = 'hero.mainButton.clicked',
  HeroTwitterButtonClicked = 'hero.twitterButton.clicked',
  HeroFeaturedImageClicked = 'hero.featuredImage.clicked',

  // Wallet Actions
  ConnectButtonClicked = 'header.connectButton.clicked',
  AccountButtonClicked = 'header.accountButton.clicked',
  WalletConnected = 'wallet.connected',

  FeaturedCollectionClicked = 'featured.collection.clicked',
  SweepModeButtonClicked = 'collection.sweepModeButton.clicked',
  SweepModePanelClose = 'sweepModePanel.closeButton.clicked',
  SweepModePanelTotalItemsInputClick = 'sweepModePanel.totalItemInput.clicked',
  SweepModePanelSliderClick = 'sweepModePanel.slider.clicked',
  ItemClicked = 'collection.item.clicked',
  ItemExternalSourceClicked = 'collection.item.externalSource.clicked',

  // checkout
  CheckOutClearButtonClicked = 'checkout.cart.clearButton.clicked',
  CheckOutRemoveItemClicked = 'checkout.cart.removeItem.clicked',
  CheckOutButtonClicked = 'checkout.checkOutButton.clicked',
  CheckOutReviewOrderClicked = 'checkout.reviewOrderButton.clicked',

  // checkout status
  CheckOutTransactionCannotSent = 'checkout.transaction.cannotSent',
  CheckOutTransactionSuccessful = 'checkout.transaction.successful',
  CheckOutTransactionReverted = 'checkout.transaction.reverted',
  CheckOutTransactionSent = 'checkout.transaction.sent',

  // confirmation panel
  CheckOutConfirmationCheckOutButtonClicked = 'checkoutConfirmation.checkOutButton.clicked',
  CheckOutConfirmationCancelButtonClicked = 'checkoutConfirmation.cancelButton.clicked',
  CheckOutConfirmationRemoveItemClicked = 'checkoutConfirmation.removeItem.clicked',
  CheckOutConfirmationDoneClicked = 'checkoutConfirmation.doneButton.clicked',
  CheckOutConfirmationViewTxClicked = 'checkoutConfirmation.viewTxButton.clicked',
  CheckOutConfirmationShareClicked = 'checkoutConfirmation.shareButton.clicked',
  CheckOutConfirmationCartClearClicked = 'checkoutConfirmation.cartClearButton.clicked',

  ExternalLinkedClicked = 'externalLink.clicked',
  LatestSaleActivitiesLinkToExplorerClicked = 'latestSaleActivities.linkToExplorer.clicked',
  LatestSaleActivitiesLinkToMarketplaceClicked = 'latestSaleActivities.linkToMarketplace.clicked',
  TermsSignatureRequested = 'terms.signatureRequested',
  TermsSignatureSigned = 'terms.signatureSigned',
  TermsSignatureRejected = 'terms.signatureRejected',
  FooterLinkClicked = 'footer.link.clicked',

  // Search
  SearchClicked = 'search.clicked',
  SearchInput = 'search.input',
  SearchCollectionClicked = 'search.collection.clicked',

  // Sorting
  Sorted = 'sort.clicked',
}

export type RevenueOpts = {
  productId?: string
  quantity?: number
}

export const AnalyticsContext = React.createContext<AnalyticsOpts>({
  environment: 'unknown',
})

export const AnalyticsProvider: React.FC<{
  children: React.ReactNode
  opts: AnalyticsOpts
}> = ({ children, opts }) => {
  initAnalytics(opts)

  return (
    <AnalyticsContext.Provider value={opts}>
      {children}
    </AnalyticsContext.Provider>
  )
}

export const initAnalytics = (opts: AnalyticsOpts) => {
  if (!opts.environment) {
    throw new Error('[initAnalytics] environment is required')
  }

  if (!opts.disableAnalytics && opts.amplitudeApiKey) {
    amplitude.init(opts.amplitudeApiKey)
  } else {
    console.log('[initAnalytics] Analytics disabled')
  }
}

export const useAnalytics = () => {
  const opts = React.useContext(AnalyticsContext)

  if (opts.disableAnalytics) {
    return {
      trackEvent: () => {},
      trackRevenue: () => {},
      identifyUser: () => {},
      identifyUserProperties: () => {},
    }
  }

  return {
    // add env to event name for ease of filtering non-prod data
    trackEvent: (
      eventType: AnalyticsEvent,
      eventProperties?: Record<string, any>,
    ) => {
      let eventName: string

      if (opts.environment === 'production') {
        eventName = eventType
      }
      // prefix env for non-prod environments
      else if (opts.environment === 'development') {
        eventName = `dev-${eventType}`
      } else {
        eventName = `${opts.environment}-${eventType}`
      }

      trackEvent(eventName, eventProperties)

      // Add sentry bredcrumb for error context
      // ref: https://docs.sentry.io/platforms/javascript/enriching-events/breadcrumbs/
      Sentry.addBreadcrumb({
        category: eventName,
        message: JSON.stringify(eventProperties),
        level: 'info',
      })
    },
    // add env to revenue type
    trackRevenue: (
      revenueAmount: number,
      revenueCurrency: 'USD' | 'ETH',
      revenueOpts: RevenueOpts,
    ) => {
      if (opts.environment === 'production') {
        trackRevenue(revenueAmount, revenueCurrency, revenueOpts)
      }
    },
    // no need to add env to user
    identifyUser,
    identifyUserProperties,
  }
}

// need to try catch all as the tracking should not disrupt the core logic
const trackEvent = (
  eventName: string,
  eventProperties?: Record<string, any>,
) => {
  try {
    amplitude.track(eventName, eventProperties)
  } catch (error) {
    console.error(error)
  }
}

const identifyUser = (userId: string) => {
  try {
    amplitude.setUserId(userId)
  } catch (error) {
    console.error(error)
  }
}

const identifyUserProperties = (userProperties: Record<string, any>) => {
  try {
    const identifyObj = new amplitude.Identify()

    Object.keys(userProperties).forEach((key) => {
      identifyObj.add(key, userProperties[key])
    })

    amplitude.identify(identifyObj)
  } catch (error) {
    console.error(error)
  }
}

const trackRevenue = (
  revenueAmount: number,
  revenueType: string,
  opts: RevenueOpts,
) => {
  try {
    let event = new amplitude.Revenue()
      .setPrice(revenueAmount)
      .setRevenueType(revenueType)

    if (opts.productId) {
      event = event.setProductId(opts.productId)
    }

    if (opts.quantity) {
      event = event.setQuantity(opts.quantity)
    }

    amplitude.revenue(event)
  } catch (error) {
    console.error(error)
  }
}
