// Polyfill for old android error
require('polyfill-object.fromentries')

import '../styles/globals.css'
import '@rainbow-me/rainbowkit/styles.css'
import type { AppProps } from 'next/app'
import Script from 'next/script'

import {
  RainbowKitProvider,
  getDefaultWallets,
  connectorsForWallets,
  wallet,
  lightTheme,
} from '@rainbow-me/rainbowkit'
import {
  chain,
  configureChains,
  createClient,
  useAccount,
  WagmiConfig,
} from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { APP_NAME } from '@/constants'
import {
  AnalyticsEvent,
  AnalyticsProvider,
  useAnalytics,
} from '@/libs/analytics'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { shortAddress } from '@/libs/address'
import BlueSweepToaster from '@/components/BlueSweepToaster'
import { setContext } from '@/libs/sentry'

const { chains, provider, webSocketProvider } = configureChains(
  [
    chain.optimism,
    ...(process.env.NEXT_PUBLIC_ENABLE_TESTNETS === 'true'
      ? [chain.goerli, chain.kovan, chain.rinkeby, chain.ropsten]
      : []),
  ],
  [
    // TODO: Use tenderly provider from metamask
    jsonRpcProvider({
      rpc: (chain) => ({
        http: 'https://optimism-mainnet.public.blastapi.io',
      }),
    }),
    // getDefaultProvider(),
    // alchemyProvider({ alchemyId: '_gg7wSSi0KMBsdKnGVfHDueq6xMB9EkC' }),
    publicProvider(),
  ],
)

const { wallets } = getDefaultWallets({
  appName: APP_NAME,
  chains,
})

const appInfo = {
  appName: APP_NAME,
}

const connectors = connectorsForWallets([
  {
    groupName: 'Popular',
    wallets: [
      wallet.metaMask({ chains }),
      wallet.walletConnect({ chains }),
      wallet.coinbase({ appName: APP_NAME, chains }),
    ],
  },
  {
    groupName: 'Other',
    wallets: [
      wallet.rainbow({ chains }),
      wallet.trust({ chains }),
      wallet.ledger({ chains }),
      wallet.argent({ chains }),
    ],
  },
])

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
  webSocketProvider,
})

function MyApp({ Component, pageProps }: AppProps) {
  const { asPath } = useRouter()
  const { trackEvent, identifyUser, identifyUserProperties } = useAnalytics()
  useAccount({
    onConnect: ({ address, connector }) => {
      const walletData = {
        address: address ? shortAddress(address) : '',
        connectorId: connector?.id,
        connectorName: connector?.name,
      }

      trackEvent(AnalyticsEvent.WalletConnected, walletData)
      setContext('wallet', walletData)

      if (address) {
        identifyUser(shortAddress(address))
        identifyUserProperties({
          address,
          connector: connector,
        })
      }
    },
  })

  useEffect(() => {
    if (asPath && trackEvent) {
      trackEvent(AnalyticsEvent.PageViewed, { path: asPath })
    }
  }, [asPath, trackEvent])

  return (
    <div>
      <div>
        <BlueSweepToaster />
      </div>
      <Component {...pageProps} className="hidden md:block" />
    </div>
  )
}

function MyAppWithProviders(props: AppProps) {
  return (
    <AnalyticsProvider
      opts={{
        amplitudeApiKey:
          process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY ||
          '80f4d7e81151f92ac61ae81bffb9c129',
        environment: process.env.NODE_ENV,
        disableAnalytics: process.env.NEXT_PUBLIC_DISABLE_ANALYTICS === 'true',
      }}
    >
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider
          appInfo={appInfo}
          chains={chains}
          showRecentTransactions={true}
          theme={lightTheme({
            accentColor: '#6862f1',
            borderRadius: 'medium',
            overlayBlur: 'small',
          })}
        >
          <MyApp {...props} />
          <FreshChatScript />
          {/* Google Analytics */}
          {process.env.NEXT_PUBLIC_DISABLE_ANALYTICS === 'true' ? null : (
            <>
              <GTagScript />
              <MsClarityScript />
            </>
          )}
        </RainbowKitProvider>
      </WagmiConfig>
    </AnalyticsProvider>
  )
}

// ref:
// - api docs: https://developers.freshchat.com/web-sdk/#web-messenger
// - open on click: https://support.freshchat.com/en/support/solutions/articles/238329-i-want-the-web-messenger-to-open-when-a-user-clicks-on-a-link-or-button-on-my-website
// - dashboard: https://bluematterlabs.freshchat.com/a/654427286753635/settings/messenger/all/web/deploy
const FreshChatScript = () => {
  const { asPath } = useRouter()

  useEffect(() => {
    if (!window.fcWidget) {
      console.error('Freshchat widget not loaded')
      return
    }
    // show only at the home page
    if (asPath === '/') {
      window.fcWidget.show()
    } else {
      window.fcWidget.hide()
    }
  }, [asPath])

  return (
    <Script id="freshchat-script" strategy="afterInteractive">
      {`
        function initFreshChat() {
          window.fcWidget.init({
              token: "391b935e-36f2-4bae-a2bb-c3f4770d5c7d",
        host: "https://wchat.freshchat.com"
          });
        }
        function initialize(i,t){var e;i.getElementById(t)?
        initFreshChat():((e=i.createElement("script")).id=t,e.async=!0,
        e.src="https://wchat.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))}
        function initiateCall(){initialize(document,"Freshchat-js-sdk")}
        window.addEventListener?window.addEventListener("load",initiateCall,!1):
        window.attachEvent("load",initiateCall,!1);
      `}
    </Script>
  )
}

const GTagScript = () => {
  return (
    <>
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-P84ZL377WE"
        strategy="afterInteractive"
      />

      <Script id="google-analytics" strategy="afterInteractive">
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-P84ZL377WE');
      `}
      </Script>
    </>
  )
}

const MsClarityScript = () => {
  return (
    <Script id="ms-clarity" strategy="afterInteractive">
      {`
        (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "djx8dfcm5v");
      `}
    </Script>
  )
}

export default MyAppWithProviders
