// import Sentry, { SeverityLevel } from '@sentry/nextjs'
import * as Sentry from "@sentry/nextjs";

// con update this for the uniform error data
type AppContext = {
  transaction?: {
    hash: string
  }
  web3?: {
    error: string
    data?: any
  }
  error?: {
    message: string
  }
}

export function captureError(level: Sentry.SeverityLevel, error: any): void {
  Sentry.withScope((scope) => {
    scope.setLevel(level);
    Sentry.captureException(error);
  });
}

// `errorCode` should be a short static string that can be easily grouped
// to provide more information, use context to add more information
export function captureMessage(level: Sentry.SeverityLevel, errorCode: string, context: AppContext = {}): void {
  Sentry.withScope((scope) => {
    // loop set all context to scope
    Object.keys(context).forEach(key => {
      scope.setContext(key, context[key as keyof AppContext] || {});
    })

    scope.setLevel(level);
    Sentry.captureMessage(errorCode);
  });
  
}

export function setContext(name: string, context: Record<string, any>) {
  Sentry.setContext(name, context)
}