export const APP_NAME = 'BlueSweep'
export const API_HOST = getApiEndpoint()
// export const API_HOST = 'http://localhost:8080'

export const BLUESWEEP_OPERATOR_CONTRACT =
  '0xbbbbbbbef0a891b553f4b43864de6c7f9a8bdf24'

// Application Config
export const MAX_SWEEP_ITEMS = 30
// The period to display the new collection after launched
export const NEW_COLLECTION_PERIOD_HR = 24 * 10 // 10 days

// Links
export const BLUESWEEP_TWITTER_URL = 'https://twitter.com/bluesweep_xyz'
export const BLUESWEEP_TWITTER_FOLLOW_URL =
  'https://twitter.com/intent/follow?screen_name=bluesweep_xyz'
export const BLUESWEEP_BLOG_URL = 'https://mirror.xyz/blog.bluesweep.eth'
export const BLUESWEEP_PRESS_KIT_URL =
  'https://drive.google.com/drive/folders/16NOuK8P66ASgZv-n1C0YHquSMLPdJ5q2?usp=sharing'

// Web Metadata
export const META_TITLE =
  'bluesweep.xyz | Easiest way to Sweep NFTs on Optimism and save up to 76% of gas'
export const META_DESC = `Save up to 76% gas for buying NFTs. Sweep up to ${MAX_SWEEP_ITEMS} NFTs at once.Discover the curated NFTs in the Optimism markets.`
export const META_IMAGE = 'https://www.bluesweep.xyz/bluesweep-og-Image.png'
export const META_TWITTER_HANDLE = '@bluesweep_xyz'
export const OPTIMISM_ETH_WHALE_ADDRESS =
  '0x4200000000000000000000000000000000000006' // use weth address to ensure the balance will always be enough
export const MULTICALL3_ADDRESS = '0xcA11bde05977b3631167028862bE2a173976CA11'

export const CHAIN_IDS = {
  MAINNET: 1,
  OPTIMISM: 10,
  ARBITRUM: 42161,
}

function getApiEndpoint(): string {
  const env = process.env.NEXT_PUBLIC_APP_ENV

  // use dev api for vercel preview branch only
  switch (env) {
    case 'preview':
      return 'https://api-dev.bluesweep.xyz'

    case 'local':
      return 'http://localhost:8080'

    default:
      // default to Prod API even if the env is missing
      return 'https://api-prod.bluesweep.xyz'
  }
}
