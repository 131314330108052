import toast, { Toaster, ToastBar } from 'react-hot-toast'

const BlueSweepToaster = () => {
  return (
    <Toaster>
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <div
              onClick={() => toast.dismiss(t.id)}
              className="flex cursor-pointer select-none"
            >
              {icon}
              {message}
            </div>
          )}
        </ToastBar>
      )}
    </Toaster>
  )
}

export default BlueSweepToaster
